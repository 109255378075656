<template>
    <div id="TextEditor">
        <div id="TextEditorNav">
            <div ref='toolbarContainer'></div>
        </div>
        <div id="TextEditorMain">
            <div id="TextEditorMainCode" style="overflow-y: auto;height: 654px;">
                <div ref='textContainer'></div>
            </div>
            <div id="TextEditorMainRight">
                <!--<div id="TextEditorMainShow" style="overflow-y: auto;" v-html="articleHtml"></div>-->
                <!--<phonePreview></phonePreview>-->
                <div class="phonePreview">
                    <img src="../assets/images/手机背景.png" alt="">
                    <div class="phonePreviewHtml nui-scroll" v-html="articleHtml"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import {utilsUploadFile} from '../utils/utils.js'

    import WangEditor from 'wangeditor';

    // 设置菜单
    const menus = [
        'head',
        'bold',
        'fontSize',
        'fontName',
        'italic',
        'underline',
        'strikeThrough',
        'indent',
        'lineHeight',
        'foreColor',
        'backColor',
        'justify',
        // 'link',
        // 'list',
        // 'todo',
        // 'quote',
        'emoticon',
        'image',
        'table',
        'splitLine',
        'undo',
        'redo',
    ]
    export default {
        name: "TextEditor",
        data() {
            return {
                articleHtml: "",
                textJSON: null,
                editor: null,
                editMsg: null,
                imgFirstUrl: '',
            }
        },
        props: {
            textEditorStart: [Object],
        },
        watch: {
            textEditorStart(val) {
                this.editMsg = val
            }
        },
        mounted() {
            setTimeout(() => {
                this.start()
            }, 500)
        },
        methods: {
            start() {
                // 设置工具栏和编辑区分开
                this.editor = new WangEditor(this.$refs.toolbarContainer, this.$refs.textContainer);
                // 设置z-index
                const editor = this.editor;
                editor.config.styleWithCSS = true;
                editor.config.zIndex = 1;
                // 设置内容变化事件
                editor.config.onchange = (newHtml) => {
                    this.onChange(newHtml)
                    this.onChange2(editor.txt.getJSON())
                };
                // 设置placeholder
                editor.config.placeholder = '请输入博文内容';
                // 图片菜单配置
                editor.config.menus = menus;
                // 图片上传配置
                editor.config.showLinkImg = false;
                editor.config.showLinkImgAlt = false;
                editor.config.showLinkImgHref = false;
                // 自己实现图片上传
                editor.config.customUploadImg = (resultFiles, insertImgFn) => {
                    this.updateImg(resultFiles, insertImgFn);
                }
                editor.config.height = 200;
                editor.create();
                if (this.editMsg) {
                    this.articleHtml = this.editMsg.articleHtml;
                    this.textJSON = this.editMsg.textJSON;
                    if (this.editMsg.textJSON) {
                        editor.txt.setJSON(this.editMsg.textJSON);
                    }
                }
            },
            onChange2(textJSON) {
                console.log(textJSON);
                this.textJSON = textJSON;
            },
            onChange(newHtml) {
                console.log(newHtml);
                this.articleHtml = newHtml;
            },
            updateImg(images, insertImgFn) {
                if (images.length > 0) {
                    let requestList = {};
                    let promiseList = [];
                    for (let i = 0; i < images.length; i++) {
                        requestList['request' + i] = utilsUploadFile(images[i], 'import')
                        promiseList.push(requestList['request' + i]);
                    }
                    Promise.all(promiseList).then(values => {
                        values.forEach(item => {
                            insertImgFn(item.path);
                        })
                    });
                }
            }
        }
    }
</script>

<style scoped>

    @import "../assets/css/base.css";

    #TextEditor {
        height: 100%;
        height: 760px;
        box-sizing: border-box;
        padding-bottom: 42px;
        position: relative;
    }

    #TextEditor #TextEditorNav {
        height: 40px;
        width: 98%;
        margin-left: 1%;
        margin-right: 1%;
        /*background-color: yellow;*/
    }

    #TextEditor #TextEditorMain {
        width: 100%;
        height: 100%;
        margin-top: 10px;
        margin-bottom: 10px;
        justify-content: space-around;
        box-sizing: border-box;
        padding-right: 480px;
        padding-left: 10px;
    }

    #TextEditor #TextEditorMain #TextEditorMainCode {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        border: 1px solid #eee;
    }

    #TextEditorMainRight {
        width: 460px;
        height: 100%;
        box-sizing: border-box;
        padding: 10px;
        /*border: 1px solid #eee;*/
        position: absolute;
        top: 0;
        right: 0;
    }

    #TextEditor #TextEditorMain #TextEditorMainShow {
        width: 100%;
        height: 100%;
    }

    #TextEditor .toolbar {
        border: 1px solid #ccc;
    }

    #TextEditor .text {
        border: 1px solid #ccc;
        min-height: 400px;
    }

    /*追加样式*/
    /* table 样式 */
    table {
        border-top: 1px solid #ccc;
        border-left: 1px solid #ccc;
    }

    table td,
    table th {
        border-bottom: 1px solid #ccc;
        border-right: 1px solid #ccc;
        padding: 3px 5px;
    }

    table th {
        border-bottom: 2px solid #ccc;
        text-align: center;
    }

    /* blockquote 样式 */
    blockquote {
        display: block;
        border-left: 8px solid #d0e5f2;
        padding: 5px 10px;
        margin: 10px 0;
        line-height: 1.4;
        font-size: 100%;
        background-color: #f1f1f1;
    }

    /* code 样式 */
    code {
        display: inline-block;
        *display: inline;
        *zoom: 1;
        background-color: #f1f1f1;
        border-radius: 3px;
        padding: 3px 5px;
        margin: 0 3px;
    }

    pre code {
        display: block;
    }

    /* ul ol 样式 */
    ul, ol {
        margin: 10px 0 10px 20px;
    }

    .phonePreview {
        width: 440px;
        background-color: #fff;
        position: relative;
    }

    .phonePreview > img {
        width: 100%;
    }

    .phonePreviewHtml {
        width: 270px;
        height: 578px;
        box-sizing: border-box;
        /*background-color: yellow;*/
        position: absolute;
        top: 82px;
        left: 50%;
        transform: translateX(-50%);
        overflow-y: auto;
    }

</style>
