<template>
    <div class="baseMain">
        <div class="baseSearch clearfloat">
            <div class="baseSearchOne">
                <div class="baseSearchOneLeft">
                    <span>计划名称：</span>
                </div>
                <div class="baseSearchOneRight">
                    <el-input v-model="searchForm.name" placeholder="请输入内容" clearable></el-input>
                </div>
            </div>
            <div class="baseSearchOne">
                <el-button type="primary" style="margin-left: 20px;" @click="search">搜索</el-button>
                <el-button @click="cleanAll">清空</el-button>
            </div>
        </div>
        <div class="baseOperate clearfloat">
            <div class="baseOperateOne">
                <el-button type="primary" @click="openAddPlan('new')" v-if="authorityStatus.new.show">创建招生计划</el-button>
            </div>
        </div>
        <div class="baseTable">
            <publicTable :publicTableOtherHeight="searchHeight" :publicTableData="tableData">
                <el-table-column prop="plan_id" label="计划ID"></el-table-column>
                <el-table-column prop="name" label="计划名称"></el-table-column>
                <!--<el-table-column prop="number" label="计划招生总人数"></el-table-column>-->
                <el-table-column label="报名流程">
                    <template #default="scope">
                        <!--{{scope.row.sign_up_name_msg}}-->
                        <template v-for="(item,index) in scope.row.sign_up">
                            <el-tag :key="index" style="margin: 4px 4px 4px 0;" v-if="index <= 4">
                                {{item.batch_name}}
                            </el-tag>
                        </template>
                        <el-link v-if="scope.row.sign_up.length > 5" class="tableButton" type="primary"
                                 @click.prevent="openAllClass(scope.row)">
                            查看全部
                        </el-link>
                    </template>
                </el-table-column>
                <el-table-column label="操作">
                    <template #default="scope">
                        <el-link class="tableButton" type="success" @click.prevent="downBaoming(scope.row)">
                            下载报名二维码
                        </el-link>
                        <el-link class="tableButton" type="primary"
                                 @click.prevent="openAddPlan('edit',scope.row)" v-if="authorityStatus.edit.show">
                            编辑
                        </el-link>
                        <el-link class="tableButton" type="danger" @click.prevent="openDel(scope.row)"
                                 v-if="authorityStatus.del.show">
                            删除
                        </el-link>
                    </template>
                </el-table-column>
            </publicTable>
        </div>
        <div class="basePage" style="margin-top: 4px;">
            <publicPage ref="publicPageUserStudent" :publicPageTotal="{total:total}"
                        @pageChange="getTableData"></publicPage>
        </div>
        <el-dialog title="提示" v-model="dialogVisible" width="800px" :fullscreen="true">
            <template #footer>
                <div class="dialog-footer">
                    <el-button @click="dialogVisible = false">取 消</el-button>
                    <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
                </div>
            </template>
        </el-dialog>
        <el-drawer :title="drawerTitle" v-model="drawer" :close-on-click-modal="false" :destroy-on-close="true"
                   size="80%">
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="auto">
                <el-form-item label="计划名称" prop="name">
                    <el-input v-model="ruleForm.name" style="width: 300px;"></el-input>
                </el-form-item>
                <!--<el-form-item label="计划招生总人数">-->
                <!--<el-input-number v-model="ruleForm.number" controls-position="right" :min="1"></el-input-number>-->
                <!--</el-form-item>-->
                <!--<el-form-item label="招生计划">-->
                <!--<el-input type="textarea" v-model="ruleForm.plan" :autosize="{minRows: 5}"></el-input>-->
                <!--&lt;!&ndash;<div class="container">&ndash;&gt;-->
                <!--&lt;!&ndash;<TextEditor ref="planEditor" style="width: 100%;height: 600px;"></TextEditor>&ndash;&gt;-->
                <!--&lt;!&ndash;</div>&ndash;&gt;-->
                <!--</el-form-item>-->
                <el-form-item label="招生计划">
                    <div class="container">
                        <TextEditor ref="planEditor" :text-editor-start="editTextEditor"></TextEditor>
                    </div>
                </el-form-item>
            </el-form>
            <div class="signupProcessDrawerForm" style="text-align: center;">
                <el-button class="signupPSRightButtonOne" type="primary" size="large" style="width: 160px;"
                           @click="saveAddPlan">
                    保存
                </el-button>
            </div>
        </el-drawer>
        <el-dialog title="全部报名流程" v-model="classListDialog" width="600px" :close-on-click-modal="false">
            <el-tag v-for="item in classListMsg" :key="item.recruit_id" style="margin: 8px;">
                {{ item.batch_name }}
            </el-tag>
        </el-dialog>
    </div>
</template>

<script>

    import publicPage from '../../components/publicPage.vue'

    import publicTable from '../../components/publicTable.vue'

    import TextEditor from '../../components/TextEditor.vue'

    import {businessGet, businessPost, businessPut, businessDel} from '../../api/business.js'

    import {utilsGetAuthority} from '../../utils/utils.js'

    export default {
        name: "recruitPlan",
        components: {
            publicPage,
            publicTable,
            TextEditor
        },
        data() {
            return {
                searchForm: {
                    name: ''
                },
                input: '',
                searchHeight: 300,
                tableData: [],
                total: 0,
                selMsg: null,
                dialogVisible: false,
                drawerTitle: '',
                drawer: false,
                abc: '',
                ruleForm: {
                    name: '',
                    // number: 1,
                    // plan: '',
                },
                rules: {
                    name: [
                        {required: true, message: '该项不能为空', trigger: 'blur'},
                    ],
                    // plan: [
                    //     {required: true, message: '该项不能为空', trigger: 'blur'},
                    // ],
                },
                editTextEditor: null,
                authorityStatus: {
                    new: {
                        method: 'post',
                        url: '/admin/v1/recruit_plan',
                        show: false,
                    },
                    edit: {
                        method: 'put',
                        url: '/admin/v1/recruit_plan/{id}',
                        show: false,
                    },
                    del: {
                        method: 'delete',
                        url: '/admin/v1/recruit_plan/{id}',
                        show: false,
                    },
                },
                classListMsg: [],
                classListDialog: false
            }
        },
        mounted() {
            utilsGetAuthority(this.authorityStatus)
            this.$nextTick(() => {
                let baseSearchDom = document.querySelector('.baseSearch');
                let baseOperateDom = document.querySelector('.baseOperate');
                let searchHeight = baseSearchDom.offsetHeight + baseOperateDom.offsetHeight;
                this.searchHeight = searchHeight;
                this.cleanAll();
            })
        },
        methods: {
            downBaoming(item) {
                window.open(item.qrcode);
            },
            openAllClass(val) {
                this.classListMsg = val.sign_up;
                this.classListDialog = true;
            },
            openDel(val) {
                if (val.sign_up.length > 0) {
                    this.$message({
                        showClose: true,
                        message: '已有报名流程使用当前计划，无法删除',
                        type: 'warning'
                    });
                    return
                }
                this.$confirm('从操作将删除该计划，是否继续？', '提示', {
                    confirmButtonText: '确认',
                    cancelButtonText: '取消',
                    type: 'warning',
                }).then(() => {
                    let url = '/admin/v1/recruit_plan/' + val.plan_id;
                    businessDel(url, {}).then(res => {
                        if (res.data.status == 200) {
                            this.$message({
                                showClose: true,
                                message: res.data.message,
                                type: 'success'
                            });
                            this.getTableData();
                        }
                        else {
                            this.$message({
                                showClose: true,
                                message: res.data.message,
                                type: 'warning'
                            });
                        }
                    })
                }).catch(() => {

                })
            },
            saveAddPlan() {
                let that = this;
                this.$refs.ruleForm.validate((valid) => {
                    if (valid) {
                        if (that.drawerTitle == '新建') {
                            let url = '/admin/v1/recruit_plan?page=1&page_pre=20&name=' + this.ruleForm.name;
                            businessGet(url).then(res => {
                                if (res.data.status == 200) {
                                    if (res.data.data.list.length == 0) {
                                        msgSubmit()
                                    }
                                    else {
                                        this.$confirm('计划名称重复，是否继续？', '提示', {
                                            confirmButtonText: '确认',
                                            cancelButtonText: '取消',
                                            type: 'warning',
                                        }).then(() => {
                                            msgSubmit()
                                        }).catch(() => {

                                        })
                                    }
                                }
                                else {
                                    this.$message({
                                        showClose: true,
                                        message: res.data.message,
                                        type: 'warning'
                                    });
                                }
                            })
                        }
                        else {
                            msgSubmit()
                        }
                    }
                })

                function msgSubmit() {
                    let url = '/admin/v1/recruit_plan';
                    let data = {
                        name: that.ruleForm.name,
                        // number: that.ruleForm.number,
                        // plan: that.ruleForm.plan,
                        plan: '',
                        original_plan: '',
                    }
                    console.log(that.$refs.planEditor.articleHtml, that.$refs.planEditor.textJSON);
                    if (that.$refs.planEditor.articleHtml) {
                        data.plan = encodeURIComponent(that.$refs.planEditor.articleHtml)
                    }
                    if (that.$refs.planEditor.textJSON) {
                        data.original_plan = encodeURIComponent(JSON.stringify(that.$refs.planEditor.textJSON))
                    }
                    if (that.drawerTitle == '新建') {
                        businessPost(url, data).then(res => {
                            if (res.data.status == 200) {
                                that.$message({
                                    showClose: true,
                                    message: res.data.message,
                                    type: 'success'
                                });
                                that.drawer = false;
                                that.getTableData();
                            }
                            else {
                                that.$message({
                                    showClose: true,
                                    message: res.data.message,
                                    type: 'warning'
                                });
                            }
                        })
                    }
                    else if (that.drawerTitle == '编辑') {
                        url = url + '/' + that.selMsg.plan_id;
                        businessPut(url, data).then(res => {
                            if (res.data.status == 200) {
                                that.$message({
                                    showClose: true,
                                    message: res.data.message,
                                    type: 'success'
                                });
                                that.drawer = false;
                                that.getTableData();
                            }
                            else {
                                that.$message({
                                    showClose: true,
                                    message: res.data.message,
                                    type: 'warning'
                                });
                            }
                        })
                    }
                }
            },
            openAddPlan(sort, val) {
                this.ruleForm.name = '';
                this.editTextEditor = null;
                // this.ruleForm.number = 1;
                // this.ruleForm.plan = '';
                if (sort == 'new') {
                    this.drawerTitle = '新建'
                    this.drawer = true;
                }
                else if (sort == 'edit') {
                    this.drawerTitle = '编辑'
                    this.selMsg = val;
                    this.ruleForm.name = this.selMsg.name;
                    // this.ruleForm.number = this.selMsg.number;
                    // this.ruleForm.plan = this.selMsg.plan;
                    this.drawer = true;
                    this.$nextTick(() => {
                        let a = {
                            articleHtml: null,
                            textJSON: null,
                        }
                        if (this.selMsg.plan != null) {
                            a.articleHtml = decodeURIComponent(this.selMsg.plan)
                        }
                        if (this.selMsg.original_plan != null) {
                            a.textJSON = JSON.parse(decodeURIComponent(this.selMsg.original_plan))
                        }
                        this.editTextEditor = a;
                        // this.$refs.planEditor.articleHtml = this.selMsg.plan;
                        // this.$refs.planEditor.textJSON = JSON.parse(this.selMsg.original_plan);
                    })
                }
            },
            cleanAll() {
                this.searchForm.name = '';
                this.search();
            },
            search() {
                this.$refs.publicPageUserStudent.currentPage = 1;
                this.getTableData();
            },
            getTableData() {
                let url = '/admin/v1/recruit_plan?page=' + this.$refs.publicPageUserStudent.currentPage + '&page_pre=' + this.$refs.publicPageUserStudent.pageSize;
                for (let k in this.searchForm) {
                    if (this.searchForm[k]) {
                        url = url + '&' + k + '=' + this.searchForm[k];
                    }
                }
                this.tableLoading = true;
                businessGet(url).then(res => {
                    this.tableLoading = false;
                    if (res.data.status == 200) {
                        this.total = res.data.data.count;
                        let tableData = res.data.data.list;
                        tableData.forEach(item => {
                            if (item.sign_up.length > 0) {
                                let num = 5;
                                if (item.sign_up.length < 5) {
                                    num = item.sign_up.length;
                                }
                                let newArray = [];
                                for (let i = 0; i < num; i++) {
                                    newArray.push(item.sign_up[i].batch_name)
                                }
                                item.sign_up_name_msg = newArray.join('，');
                                if (num > 5) {
                                    item.sign_up_name_msg += ' 等' + item.sign_up.length + '个'
                                }
                            }
                        })
                        this.tableData = tableData;
                    }
                    else {
                        this.$message({
                            showClose: true,
                            message: res.data.message,
                            type: 'warning'
                        });
                    }
                })
            },
        }
    }
</script>

<style scoped>

    @import "../../assets/css/base.css";

    .container {
        width: 100%;
        /*height: 600px;*/
        box-sizing: border-box;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    }

    .signupProcessDrawerForm {
        width: 800px;
        margin: 20px auto;
    }

    .signupPSRightButtonOne {
        margin: 0 6px;
    }

</style>
